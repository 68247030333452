/**
 * File Description: Header
 */
import React, {Component} from 'react';
import '../App.css';
import {AppBar, Button} from "@material-ui/core"

export default class Header extends Component{
    constructor(props){
        super(props);
        this.redirectToResume = this.redirectToResume.bind(this);
    }

    redirectToResume=()=>{
        this.props.history.push({pathname:"/resume"})
    }
    
    redirectToAboutMe = () =>{
        this.props.history.push({pathname:"/"})    
    }

    redirectToProjects = () =>{
        this.props.history.push({pathname:"/projects"})
    }

    render(){

        return(
            <div className="App-header">
                {/* <AppBar position="fixed" className="App-header"> */}
                    <Button onClick={this.redirectToAboutMe}>About Me</Button>
                    <Button onClick={this.redirectToProjects}>Projects</Button>
                    <Button onClick={this.redirectToResume}>Resume</Button>
                {/* </AppBar> */}
            </div>
        )
    }
}