import React, {Component} from "react";
import {Avatar, Icon} from "@material-ui/core";
import "../App.css";
import Header from "./Header";
import Footer from "./Footer"
import projects from "../projects.json";

//material/ui imports
import {IconButton} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GitHubIcon from '@material-ui/icons/GitHub';


export default class Projects extends Component{
   
    constructor(props){
        super(props);
        this.state = {
            projects:[],
        }
    }
    

    componentDidMount(){
        this.setState({projects: [...projects.data]});
        console.log(this.state);

    }

    fetchProjectsFromJson=async()=>{
        console.log("Fetching Projects from the projects.json file");
    }

    handleGithubRedirect = (url) =>{
        console.log("Redirecting to Github: ");
        const githubPage = window.open(url, "_blank");
        if (githubPage != null) {
          githubPage.focus();
        }
    }


    render(){ 

        return(
            <div>
                <Header history={this.props.history}/>
                <div className="App-projects-body">
                   <Grid container xs={12} spacing={2} alignItems="center" justify="space-evenly">
                   {this.state.projects.map((project, id)=>{
                       return(
                     <Grid item xs={6} alignItems="center">
                       <Card className="App-project-card">
                           <CardActionArea>
                             <CardMedia
                                style={{objectFit:"cover"}}
                                component="img"
                                alt="project_image"
                                height={340}
                                src= {project.thumbnail}
                                title={project.title}
                                />
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {project.title}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                   {project.description}
                                </Typography>
                                </CardContent>
                           </CardActionArea>
                           <CardActions>
                               <IconButton onClick={()=>this.handleGithubRedirect(project.github)}>
                                   <GitHubIcon/>
                               </IconButton>
                            </CardActions>
                       </Card>
                       </Grid>)
                   })}
                   </Grid>
                </div>
                <Footer/>
            </div>
        )
    }
}