/**
 * File Description: Component to show my resume
 */
import React, {Component} from 'react';
// import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import {Document, Page, pdfjs} from 'react-pdf';
import Header from './Header';
import Footer from "./Footer";
import '../App.css';
import Resume_ from '../assets/Resume.pdf'



export default class Resume extends Component{
    constructor(props){
        super(props);
    }

    componentDidMount(){
        //Setting up the pdfjs
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }
    
    onDocumentLoadSuccess = () =>{
        console.log("Successfully Loaded Resume");
    }

    onDocumentLoadError = () =>{
        console.log("there is some problem loading the pdf");
    }

    render(){

        return(
            <div>
                <Header history={this.props.history}/>         
                <div className="App-body" style={{paddingTop:100}}>
                <Document
                    file= {Resume_}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    onLoadError={this.onDocumentLoadError}
                    >
                    <Page pageNumber={1}/>
                    <Page pageNumber={2}/>
                    </Document>
                </div>
                <Footer />
            </div>
        )
    }
}