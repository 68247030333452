/**
 * File Description: Entry file for the app
 */
import React from 'react';
import { Router, Route, Switch } from "react-router-dom";
import history from "./history.js";
import './App.css';
import Projects from './components/Projects';
import AboutMe from './components/AboutMe';
import Resume from './components/Resume';
import WatchTogetehr from "./components/tools/us/WatchTogether";
import Chat from "./components/tools/us/Chat";
import Us from "./components/tools/us/Us";


function App() {
  return (
 
    <Switch>
      <Route
          exact
          path="/"
          component={AboutMe}
          history={history}
        />
        <Route exact path="/projects" component={Projects}/>
        <Route exact path="/resume" component={Resume}/>
        <Route path="/us/watch" component={WatchTogetehr}/>
        <Route path="/us/chat" component={Chat}/>
        <Route us="/us" component={Us}/>

      </Switch>
  );
}

export default App;
