/**
 * File Description: About Me Page
 */
import React, {Component} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import '../App.css';

//@material-ui imports
import {Avatar, Button, SvgIcon} from "@material-ui/core";
import CodeIcon from '@material-ui/icons/Code';

export default class AboutMe extends Component{
    constructor(props){
        super(props);
        console.log(props);
    }

    componentDidMount(){

    }



    render(){

        return(
            <div>
                <Header history={this.props.history}/>
                <div className="App-body">
                    <img src={require('../assets/me.png')} className="App-logo" alt="logo" />
                    {/* <Avatar alt="Gagan Bajwa" src={require('../assets/me.png')} sizes="large"/> */}
                    Hey! this is Gagandeep Singh 
                    <p>
                    Dedicated and passionate Software Developer with professional experience in Machine Learning and Application Development
                    </p>
                    {/* <p><CodeIcon style={{background:"white", borderRadius:"50%", color:"black"}}/>  */}
                    {/* <div style={{display:"flex", justifyItems:"center"}}> 
                        <CodeIcon style={{background:"white", borderRadius:"50%", color:"black", height:"1.5em", width:"1.5em", }}/>   
                        <img src={require("../assets/entrepreneur-icon.svg")} style={{background:"white", borderRadius:"50%", height:"1.5em", width:"1.5em"}}/> 
                        <img src={require("../assets/machine-learning-icon.svg")} style={{background:"white", borderRadius:"50%", height:"1.5em", width:"1.5em"}}/>
                    </div> */}
                    {/* <Button style={{background:"white"}}>Hello</Button> */}
                </div>
                <Footer />
            </div>
        )
    }
}