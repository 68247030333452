/**
 * File Description: Watch videos with a loved ones
 */

import React, {Component} from 'react';
import YouTube from "react-youtube";
import './Us.css';
import firebase from "../../../Firebase";

//material/ui imports
import {IconButton} from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';



export default class WatchTogether extends Component{
    
    constructor(props){
        super(props);
        this.state={
            player_state:-1,
            player:null
        }
    }

    componentDidMount(){
        //getting the player state from database
        firebase.database().ref("player_state").on("value", (data)=>{
           this.setState({player_state: data.val()}); 
           if(this.state.player!=null){
            switch(this.state.player_state){
                case 1:
                    this.state.player.playVideo();
                    break;
                    case 2:
                        this.state.player.pauseVideo();
                        break;
            }
         }
        });

    }

    handlePlayButtonClick=(event)=>{
        //playing the cideo
        event.target.playVideo();    
    }

    handlePlayerStateChange=(event)=>{
        console.log("hello",event);

        //update the player state in the database
        firebase.database().ref("player_state").set(event.data);
    }

    handlePlayerOnReady=(event)=>{
        this.setState({player:event.target});
        console.log(this.state);
    }
    
    render(){

        const playerOptions = {
            playerVars:{
                loop:1,
                autoplay:0
            }
        }

        return(
            <div className="Us-body"> 
                <p>Hey welcome my love</p>
                <YouTube opts={playerOptions} videoId="T4MMwYbRBBM" onStateChange={this.handlePlayerStateChange} onReady={this.handlePlayerOnReady}></YouTube>
                {/* <IconButton onClick={()=>this.handlePlayButtonClick()}>
                    <PlayCircleOutlineIcon className="Us-button"/>
                </IconButton> */}
            </div>  
        );
    }
}