/**
 * File Description: Initializing the firebase instance
 */
import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyCNM5zhVUdltOoH0ow1GEMF2E8qRQO57TY",
    authDomain: "navgag-9a24b.firebaseapp.com",
    databaseURL: "https://navgag-9a24b.firebaseio.com",
    projectId: "navgag-9a24b",
    storageBucket: "navgag-9a24b.appspot.com",
    messagingSenderId: "660200712009",
    appId: "1:660200712009:web:97d6dd82fe250ea107dc98",
    measurementId: "G-1HWTZM1HH3"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;