/**
 * File Description: Footer
 */
import React, {Component} from 'react';
import '../App.css';

//@material-ui imports
import {AppBar, Button} from "@material-ui/core";
import {IconButton} from '@material-ui/core';
import GitHubIcon from '@material-ui/icons/GitHub';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

export default class Footer extends Component{
    constructor(props){
        super(props);
    }

    handleRedirect = (url) =>{
        const redirectPage = window.open(url, "_blank");
        if (redirectPage != null) {
          redirectPage.focus();
        }
    }

    render(){

        return(
            <div className="App-footer">
                <IconButton onClick={()=>this.handleRedirect("https://github.com/GagandeepBajwa")}>
                    <GitHubIcon />
                </IconButton>
                <IconButton onClick={()=>this.handleRedirect("https://www.facebook.com/gagandeep.bajwa.1428")}>
                    <FacebookIcon />
                </IconButton>
                <IconButton onClick={()=>this.handleRedirect("mailto:GagandeepSinghBajwa@outlook.com")}>
                    <EmailIcon />
                </IconButton>
                <IconButton onClick={()=>this.handleRedirect("https://twitter.com/gagandeepbajw17")}>
                    <TwitterIcon />
                </IconButton>
                <IconButton onClick={()=>this.handleRedirect("https://www.linkedin.com/in/gagandeepsinghbajwa/")}>
                    <LinkedInIcon />
                </IconButton>
            </div>
        )
    }
}