/**
 * File Description: Chat with your loved One
 */

import React, {Component} from 'react';
import './Us.css';
import firebase from "../../../Firebase";
import {ChatItem,
        MessageList,
        MessageBox,
        Input,
        Button,
        } from "react-chat-elements";

//importing external CSS
// RCE CSS
import 'react-chat-elements/dist/main.css';

export default class Chat extends Component{

    inputRef = React.createRef();
    
    constructor(props){
        super(props);
        this.state={
            message:"",
            messageList:[],
        }
    }

    componentDidMount(){

        //get the messages from the firebase and add change the state
        firebase.database().ref("messages").on("value", (data)=>{
            console.log(data.val());
            this.setState({messageList: Object.values(data.val())});
        });
        
    }

    handleInputChange=(event)=>{
        console.log("value in the input changed");
        //change the message state to the changed value
        this.setState({message:event.target.value});
    }

    sendButtonClickHandler=()=>{
        console.log("Button Pressed");
        //add the message to the database only when its not empty
        if(this.state.message!=""){
            this.addMessageToTheDatabase(this.state.message).then(()=>{
                this.setState({message:""});
                this.inputRef.clear();
            }).catch((err)=>{
                console.log(err);
            });
        }
    }
    
    addMessageToTheDatabase=(message)=>{
        //get a new message key
        var newMessageKey = firebase.database().ref().child("messages").push().key;
        var date = new Date();
        var messageData = {
            message: message,
            timestamp: date,
            timeoffset:date.getTimezoneOffset(),
            userId: "",
            type: "text",
        }
        var updates={};
        updates["/messages/"+newMessageKey] = messageData;
        return firebase.database().ref().update(updates);
    }


    
    render(){
        return(
            <div className="Us-body"> 
                <p>Hey welcome my love</p> 
                {/* <div><Widget launcher={false}/>  </div>    */}
                <div className="Us-message-list-body">
                        {
                            this.state.messageList.map((message)=>{
                                console.log(message.message);
                                return(
                                <MessageBox
                                    position={message.offset>0 ? "left":"right"}
                                    type={"text"}
                                    text={message.message}
                                    date= {new Date(message.timestamp)}
                                />
                                )
                            })
                        }
                        <Input 
                            placeholder="Type here"
                            multiline={false}
                            onChange={this.handleInputChange}
                            ref={el => (this.inputRef = el)}
                            rightButtons={
                                <Button
                                    color='White'
                                    backgroundColor='black'
                                    text='Send'
                                    value={this.state.message}
                                    onClick={this.sendButtonClickHandler}/>
                            }
                        />
                </div>
    
            </div>  
        );
    }
}