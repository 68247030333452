/**
 * File Description: Chat with your loved One
 */

import React, {Component} from 'react';
import "./Us.css";
import firebase from "../../../Firebase";
import { Widget } from "react-chat-widget";


//material ui imports
import {IconButton} from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import TvIcon from '@material-ui/icons/Tv';



export default class Us extends Component{
    
    constructor(props){
        super(props);
        this.state={

        }
    }

    componentDidMount(){

    }

    handleRedirect=(redirect)=>{
        console.log("Redirecting to the appropriate page");
        switch(redirect){
            case "chat":
                this.props.history.push({pathname:"/us/chat"})
                break;
            case "watch":
                this.props.history.push({pathname:"/us/watch"})
                break;
        }
        
    }
    
    render(){
        return(
            <div className="Us-body"> 
                <p>Hey welcome my love</p>
                <IconButton onClick={()=>this.handleRedirect("chat")} >
                    <ChatIcon className="Us-button"/>
                </IconButton>
                <IconButton onClick={()=>this.handleRedirect("watch")}>
                    <TvIcon  className="Us-button"/>
                </IconButton>             
            </div>  
        );
    }
}